import React from 'react'
import '../css/about.css';
import translate from '../translate.json';
import img1 from '../img/ekkav.jpg';

export default function About({currentLanguage}) {
  return (
    <div className='about'>

        <div className='about-img'>
            <img src={img1} alt="" />
        </div>
        <div className='about-content'>
            <h2>{translate[currentLanguage].aboutTitle}</h2>
            <h6>{translate[currentLanguage].about}</h6>
        </div>

    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../css/navbar.css';
import translate from '../translate.json';
import { FaBars } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { CgGoogle, CgFacebook } from "react-icons/cg";
import { PiInstagramLogoBold } from "react-icons/pi";
import logo from '../img/logofin.png';
import { IoLocationSharp,IoCallSharp,IoMail } from "react-icons/io5";
import img from '../img/1.png';
import img2 from '../img/2.png';
import img3 from '../img/3.png';
import img4 from '../img/4.png';



export default function Navbar({currentLanguage, onLanguage}) {
    

    

    const [nav, setNav] = useState(false);

    const changeNavbarBackground = () => {
        if (window.scrollY >= 10) {
            document.querySelector('nav').style.marginTop = '0px';
            // document.querySelector('nav-reality').style.position = 'relative';
            document.querySelector('#menu-a-i').style.margin = '15px 0px 0px 10px';
            
            setNav(true);
            
        } else {
            if (window.innerWidth < 900) {
                document.querySelector('nav').style.marginTop = '40px';
            }
            document.querySelector('#menu-a-i').style.margin = '50px 0px 0px 10px';
            setNav(false);
            
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNavbarBackground);

        // Tarayıcıdan gelen URL'deki fragment'i (hash) kontrol et
        const fragment = window.location.hash;
        if (fragment) {
            const targetSection = document.querySelector(fragment);
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
            window.removeEventListener('scroll', changeNavbarBackground);
        }
    }, []);

    const openSecondNav = () => {
        document.querySelector('.secondNav').style.left = '0px';
        document.querySelector('#menu-a-i').style.display = 'none';
        document.querySelector('#menu-a-i-2').style.display = 'flex';
        document.querySelector('#menu-a-i-2').style.color = 'white';
    }

    const closeSecondNav = () => {
        document.querySelector('.secondNav').style.left = '-500px';
        document.querySelector('#menu-a-i').style.display = 'flex';
        document.querySelector('#menu-a-i-2').style.display = 'none';
    }

    

    return (
        <div className='div'>
            <div className='banner'>
                    <a href="mailto:muhasebe@unorga.com"><IoMail className='icoo'/>muhasebe@unorga.com</a>
                    <div class="scrolling-text-container">
    <div class="scrolling-text">
    {translate[currentLanguage].banner}
    </div>
  </div>
                    <a href="tel:+905325826387"><IoCallSharp className='icoo'/>0532 582 63 87</a>
            </div>
            <div className='title'>
                <a href="/"><img src={logo} alt="logo" /></a>
                
            </div>
            <FiX id='menu-a-i-2' onClick={closeSecondNav} />
            <FaBars id="menu-a-i" onClick={openSecondNav} />
            <nav id="nav" className='nav nav-reality'>
                <div className='social'>
                <a href="https://instagram.com/unorgatr?igshid=MzRlODBiNWFlZA=="><CgFacebook className='ico'/></a>
                <a href="mailto:muhasebe@unorga.com"><CgGoogle className='ico'/></a>
                <a href="https://instagram.com/unorgatr?igshid=MzRlODBiNWFlZA=="><PiInstagramLogoBold className='ico'/></a>
                </div>

                <ul className="menu-ul">
                    <li className="menu"><a href="/" className={nav ? 'menu-a1' : 'menu-a2'}>{translate[currentLanguage].nav1}</a></li>
                    <li className="menu"><a href="content_about" className={nav ? 'menu-a1' : 'menu-a2'}>{translate[currentLanguage].nav2}</a></li>
                    <li className="menu"><div class="dropdown show prod" >
                    <a class="btn btn-secondary dropdown-toggle " className={nav ? 'menu-a1' : 'menu-a2'} href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {translate[currentLanguage].nav3}
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="content_kab"  >{translate[currentLanguage].product1}</a>
                    <a class="dropdown-item" href="content_nat" >{translate[currentLanguage].product2} </a>
                    <a class="dropdown-item" href="content_bey">{translate[currentLanguage].product3} </a>
                    <a class="dropdown-item" href="content_kav"  >{translate[currentLanguage].product4}</a>
                    <a class="dropdown-item" href="content_kiy" >{translate[currentLanguage].product5} </a>
                    <a class="dropdown-item" href="content_dil">{translate[currentLanguage].product6} </a>
                    <a class="dropdown-item" href="content_unu"  >{translate[currentLanguage].product7}</a>
                    <a class="dropdown-item" href="content_pur" >{translate[currentLanguage].product8} </a>
                    
                </div>
                </div></li>
                    <li className="menu"><a href="contact" className={nav ? 'menu-a1' : 'menu-a2'}>{translate[currentLanguage].nav4}</a></li>
                    
                </ul>
                <div  class="dropdown show changeLanguage" >
                    <a  class="btn btn-secondary dropdown-toggle " href="#" role="button" style={{background:'transparent', color:'rgb(106, 65, 22)',border:'none',height:'40px',width:'60px'}} id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {translate[currentLanguage].language}
                    </a>

                    <div class="dropdown-menu " aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('en')} >EN</a>
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('tr')}>TR</a>
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('ru')} >RU</a>
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('sa')}>SA</a>
                    
                </div>
                </div>
            </nav>
            {/* fixed nav */}

            {/* <nav id="nav" className={nav ? 'nav active' : 'nav navFixed'}>
                <div className='social'>
                <a href="https://alihankara.com"><CgFacebook className='ico'/></a>
                <a href=""><CgGoogle className='ico'/></a>
                <a href=""><PiInstagramLogoBold className='ico'/></a>
                </div>

                <ul className="menu-ul">
                    <li className="menu"><a href="/" className="active menu-a">{translate[currentLanguage].nav1}</a></li>
                    <li className="menu"><a href="content_about" className={nav ? 'menu-a1' : 'menu-a2'}>{translate[currentLanguage].nav2}</a></li>
                    <li className="menu"><div class="dropdown show prod" >
                    <a class="btn btn-secondary dropdown-toggle " className={nav ? 'menu-a1' : 'menu-a2'} href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {translate[currentLanguage].nav3}
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="content_kab"  >{translate[currentLanguage].product1}</a>
                    <a class="dropdown-item" href="content_nat" >{translate[currentLanguage].product2} </a>
                    <a class="dropdown-item" href="content_bey">{translate[currentLanguage].product3} </a>
                    <a class="dropdown-item" href="content_kav"  >{translate[currentLanguage].product4}</a>
                    <a class="dropdown-item" href="content_kiy" >{translate[currentLanguage].product5} </a>
                    <a class="dropdown-item" href="content_dil">{translate[currentLanguage].product6} </a>
                    <a class="dropdown-item" href="content_unu"  >{translate[currentLanguage].product7}</a>
                    <a class="dropdown-item" href="content_pur" >{translate[currentLanguage].product8} </a>
                    
                </div>
                </div></li>
                    <li className="menu"><a href="contact" className={nav ? 'menu-a1' : 'menu-a2'}>{translate[currentLanguage].nav4}</a></li>
                    
                </ul>
                <div class="dropdown show">
                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {translate[currentLanguage].language}
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('en')} >En</a>
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('tr')}>TR</a>
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('ru')} >RU</a>
                    <a class="dropdown-item" href="#" onClick={() => onLanguage('sa')}>SA</a>
                    
                </div>
                </div>
            </nav> */}


            <div className='secondNav'>
                <ul className="menu-ul">
                    <li className="menu2"><a href="/" className="menu2-a">{translate[currentLanguage].nav1}</a></li>
                    <li className="menu2"><a href="content_about" className="menu2-a">{translate[currentLanguage].nav2}</a></li>
                    <li className="menu2"><a href="content_kab" className="menu2-a">{translate[currentLanguage].nav3}</a></li>
                    <li className="menu2"><a href="contact" className="menu2-a">{translate[currentLanguage].nav4}</a></li>

                    <div className='navbar-icons'>
                        <a href='https://instagram.com/unorgatr?igshid=MzRlODBiNWFlZA=='><PiInstagramLogoBold className='icon' /></a>
                        <a href='mailto:muhasebe@unorga.com'><CgGoogle className='icon' /></a>
                        <a href='https://instagram.com/unorgatr?igshid=MzRlODBiNWFlZA=='><CgFacebook className='icon' /></a>
                    </div>
                </ul>
            </div>
        </div>
    );
}

import React from 'react'
import '../css/product.css'
import translate from '../translate.json';
import img1 from '../img/kalite.jpeg';
import img2 from '../img/naturelfindik.jpeg';
import img3 from '../img/beyazlatilmisfindik.jpeg';
import img4 from '../img/kavv.jpg';
import img5 from '../img/kiyilmisfindik.jpeg';
import img6 from '../img/dilinmisfindik.jpeg';
import img7 from '../img/findikunu.jpeg';
import img8 from '../img/pure.jpeg';


export default function Product({currentLanguage}) {
  return (
    <div className='product'>
        <h2>{translate[currentLanguage].productTitle}</h2>
        <div className='all-cards'>
            <div className='card'>
                <a href="content_kab">
                    <img src={img1} alt="" />
                    <h6>{translate[currentLanguage].product1}</h6>
                </a>
                
            </div>

            <div className='card'>
                <a href="content_nat">
                    <img src={img2} alt="" />
                    <h6>{translate[currentLanguage].product2}</h6>
                </a>
                
            </div>

            <div className='card'>
                <a href="content_bey">
                    <img src={img3} alt="" />
                    <h6>{translate[currentLanguage].product3}</h6>
                </a>
                
            </div>

            <div className='card'>
                <a href="content_kav">
                    <img src={img4} alt="" />
                    <h6>{translate[currentLanguage].product4}</h6>
                </a>
                
            </div>

            <div className='card'>
                <a href="content_kiy">
                    <img src={img5} alt="" />
                    <h6>{translate[currentLanguage].product5}</h6>
                </a>
                
            </div>

            <div className='card'>
                <a href="content_dil">
                    <img src={img6} alt="" />
                    <h6>{translate[currentLanguage].product6}</h6>
                </a>
                
            </div>

            <div className='card'>
                <a href="content_unu">
                    <img src={img7} alt="" />
                    <h6>{translate[currentLanguage].product7}</h6>
                </a>
                
            </div>

            <div className='card'>
                <a href="content_pur">
                    <img src={img8} alt="" />
                    <h6>{translate[currentLanguage].product8}</h6>
                </a>
                
            </div>
        </div>
    </div>
  )
}

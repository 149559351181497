import React, { useState } from 'react';
import axios from 'axios';
import '../css/contact.css';
import { IoLocationSharp,IoCallSharp,IoMail } from "react-icons/io5";
import translate from '../translate.json';

function ContactForm({currentLanguage}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [tel, setTel] = useState('');
  const [message, setMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { name, email,tel, subject, message };

    axios.post('https://api.unorga.com/send-mail', formData)
      .then((response) => {
        console.log(response.data);
        setSubmitStatus(true);
      })
      .catch((error) => {
        console.error(error);
        setSubmitStatus(false);
      });
  };

  return (
    <div className='contact'>
      <div className='contact-title'>
        <h2>{translate[currentLanguage].contact_title}</h2>
        <h6>{translate[currentLanguage].contact_explanation}</h6>
      </div>

      <div className='contact-second'>
        <div className='contact-info'>
          <div>

      <div  class="footerCon">
        
        <div className='footerContent'>
          <IoCallSharp className='icc'/>
          <p>{translate[currentLanguage].footerContent2}</p>
        </div>

        <div className='footerContent'>
          <IoMail className='icc'/>
          <p>{translate[currentLanguage].footerContent3}</p>
        </div>

        <div className='footerContent'>
          <IoLocationSharp className='icc'/>
          <p>{translate[currentLanguage].footerContent4}</p>
        </div>

        <div className='footerContent'>
          <IoLocationSharp className='icc'/>
          <p>Liseler Mah. Arzum Sok. <br/>No:4 Ünye/ORDU</p> 
        </div>
        
      </div>
          </div>
        </div>
        <div className='contact-form'>
              <form onSubmit={handleSubmit} method='POST' >
                <input type="text" placeholder={translate[currentLanguage].contact_place1} value={name} onChange={(e) => setName(e.target.value)} />
                <input type="email" placeholder={translate[currentLanguage].contact_place2} value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="text" placeholder={translate[currentLanguage].contact_place3} value={subject} onChange={(e) => setSubject(e.target.value)} />
                <input type="text" placeholder={translate[currentLanguage].contact_place4} value={tel} onChange={(e) => setTel(e.target.value)} />
                <textarea placeholder={translate[currentLanguage].contact_place5} value={message} onChange={(e) => setMessage(e.target.value)} />
                <button type="submit">{translate[currentLanguage].contact_button}</button>
              </form>
              {submitStatus === true && <p className='dataSucceed'>{translate[currentLanguage].contact_succeed}</p>}
            {submitStatus === false && <p className='dataFail'>{translate[currentLanguage].contact_failed}</p>}
              
        </div>
      
    </div>
    </div>
    
    
  );
}

export default ContactForm;

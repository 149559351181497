import React from 'react'
import img1 from '../img/s2.jpg';
import img12 from '../img/s2.jpg';
import img2 from '../img/s3.jpg';
import img22 from '../img/bg-b.jpeg';
import img3 from '../img/nocciole-turchia-1217.jpg';
import img33 from '../img/bg33.jpeg';
import '../css/home.css';


export default function Home() {
  return (
    <div className='home'>
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000" >
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100"src={window.innerWidth <= 500 ? img12 : img22} alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src={window.innerWidth <= 500 ? img2 : img1}  alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src={window.innerWidth <= 500 ? img3 : img33}  alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
    </div>
  )
}

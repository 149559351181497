import React from 'react'
import '../css/ContentAbout.css';
import translate from '../translate.json';
import img from '../img/findik1.jpeg';

export default function ContentAbout({currentLanguage}) {
  return (
    <div className='content-about'>
        <img src={img} alt="" />
        <div className='description-about'>
            <h2>{translate[currentLanguage].aboutContentTitle}</h2>
            <h6>{translate[currentLanguage].aboutDescription}</h6>
        </div>
    </div>
  )
}

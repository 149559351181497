import React from 'react'
import '../css/content.css'
import translate from '../translate.json';
import img from '../img/findikunu-page.jpeg';

export default function ContentUnu({currentLanguage}) {
  return (
    <div className='content'>
        <div className='category'>
        <img src={img} alt="" />
        </div>

        <div className='text'>
        <ul class="list-group listSecond">
          <details>
            <summary><li class="list-group-item list-group-item-dark"><strong>{translate[currentLanguage].productTitle}</strong></li></summary>
         
  
  <a href="content_kab"><li class="list-group-item">{translate[currentLanguage].product1}</li></a>
  <a href="content_nat"><li class="list-group-item">{translate[currentLanguage].product2}</li></a>
  <a href="content_bey"><li class="list-group-item">{translate[currentLanguage].product3}</li></a>
  <a href="content_kav"><li class="list-group-item">{translate[currentLanguage].product4}</li></a>
  <a href="content_kiy"><li class="list-group-item">{translate[currentLanguage].product5}</li></a>
  <a href="content_dil"><li class="list-group-item">{translate[currentLanguage].product6}</li></a>
  <a href="content_unu"><li class="list-group-item">{translate[currentLanguage].product7}</li></a>
  <a href="content_pur"><li class="list-group-item">{translate[currentLanguage].product8}</li></a>
  </details>
  </ul>
  
            
  <ul class="list-group ">
  <li class="list-group-item list-group-item-dark"><strong>{translate[currentLanguage].productTitle}</strong></li>
  <a href="content_kab"><li class="list-group-item">{translate[currentLanguage].product1}</li></a>
  <a href="content_nat"><li class="list-group-item">{translate[currentLanguage].product2}</li></a>
  <a href="content_bey"><li class="list-group-item">{translate[currentLanguage].product3}</li></a>
  <a href="content_kav"><li class="list-group-item">{translate[currentLanguage].product4}</li></a>
  <a href="content_kiy"><li class="list-group-item">{translate[currentLanguage].product5}</li></a>
  <a href="content_dil"><li class="list-group-item">{translate[currentLanguage].product6}</li></a>
  <a href="content_unu"><li class="list-group-item">{translate[currentLanguage].product7}</li></a>
  <a href="content_pur"><li class="list-group-item">{translate[currentLanguage].product8}</li></a>

</ul>
            <table className="table">
  <thead>
    <tr>
      <th >{translate[currentLanguage].content_unu_1}</th>
      <th >{translate[currentLanguage].content_unu_6}</th>
      
     
      
    </tr>
  </thead>
  <tbody>
    <tr>
    <th >{translate[currentLanguage].content_unu_2}</th>
      <td >{translate[currentLanguage].content_unu_7}</td>
      
      
      
      
      
    </tr>
    <tr>
    <th >{translate[currentLanguage].content_unu_3}</th>
      <td >{translate[currentLanguage].content_unu_8}</td>
    </tr>

    <tr>
    <th>{translate[currentLanguage].content_unu_4}</th>
      <td >{translate[currentLanguage].content_unu_9}</td>
    </tr>

    <tr>
    <th>{translate[currentLanguage].content_unu_5}</th>
      <td >{translate[currentLanguage].content_unu_10}</td>
    </tr>
    
    
  </tbody>
</table>

        </div>
    </div>
  )
}

import React from 'react'
import translate from '../translate.json';
import "../css/footer.css";
import img from '../img/logofin.png';
import { FiPhoneCall  } from "react-icons/fi";
import { IoLocationSharp,IoCallSharp,IoMail } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { FcAssistant } from "react-icons/fc";

export default function Footer({currentLanguage}) {
  return (
    <div  class="containerr">
  <footer  class="py-5">
    <div  className="row justify-content-between">

        <div className='footerLogo'>
            <a href="/"><img src={img} alt="" /></a>
        </div>

      <div  class=" link">
        <h5>{translate[currentLanguage].footerTitle1}</h5>
        <ul  class=" ">
          <li  class="nav-item mb-2"><a href="/"  class="  text-muted">{translate[currentLanguage].nav1}</a></li>
          <li  class="nav-item mb-2"><a href="/content_about"  class="  text-muted">{translate[currentLanguage].nav2}</a></li>
          <li  class="nav-item mb-2"><a href="/content_kab"  class="  text-muted">{translate[currentLanguage].nav3}</a></li>
          <li  class="nav-item mb-2"><a href="/contact"  class="  text-muted">{translate[currentLanguage].nav4}</a></li>
          
        </ul>
      </div>
      <div  class=" link">
        <h5>{translate[currentLanguage].footerTitle2}</h5>
        <ul  class=" ">
          <li  class="nav-item mb-2"><a href="content_kab"  class="  text-muted">{translate[currentLanguage].product1}</a></li>
          <li  class="nav-item mb-2"><a href="content_nat"  class="  text-muted">{translate[currentLanguage].product2}</a></li>
          <li  class="nav-item mb-2"><a href="content_kiy"  class="  text-muted">{translate[currentLanguage].product5}</a></li>
          <li  class="nav-item mb-2"><a href="content_dil"  class="  text-muted">{translate[currentLanguage].product6}</a></li>
          <li  class="nav-item mb-2"><a href="content_unu"  class="  text-muted">{translate[currentLanguage].product7}</a></li>
          <li  class="nav-item mb-2"><a href="content_pur"  class="  text-muted">{translate[currentLanguage].product8}</a></li>
        </ul>
      </div>

     

      <div  class="footerCon">
        
        <div className='footerContent'>
          <IoCallSharp className='icc'/>
          <p>{translate[currentLanguage].footerContent2}</p>
        </div>

        <div className='footerContent'>
          <IoMail className='icc'/>
          <p>{translate[currentLanguage].footerContent3}</p>
        </div>

        <div className='footerContent'>
          <IoLocationSharp className='icc'/>
          <p>Liseler Mah. Arzum Sok. <br/>No:4 Ünye/ORDU</p>
        </div>
      </div>
    </div>

    <div  class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
      <p>&copy; {translate[currentLanguage].footerText}</p>
      <p>{translate[currentLanguage].designed} <a href='https://alihankara.com'>alihankara.com</a></p>

    </div>
  </footer>
  <div className='wp' ><a href='https://wa.me/+905325826387'><FaWhatsapp className='wpIco'/></a>
        
  </div>

  <div className='tel' ><a href='tel:+905325826387'><FiPhoneCall className='telIco'/></a>
        
  </div>
</div>

  )
}

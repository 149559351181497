import React,{useState, useEffect} from 'react';
import Navbar from './components/Navbar';
import './App.css';
import About from './components/About.jsx';
import Home from './components/Home.jsx';
import Product from './components/Product';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContentKav from './components/ContentKav';
import ContentKiy from './components/ContentKiy';
import ContentKab from './components/ContentKab';
import ContentNat from './components/ContentNat';
import ContentDil from './components/ContentDil';
import ContentBey from './components/ContentBey';
import ContentUnu from './components/ContentUnu';
import ContentPur from './components/ContentPur';
import ContentAbout from './components/ContentAbout';
import Contact from './components/Contact';

function App() {
  const[currentLanguage, setCurrentLanguage]=useState('tr');


//   const handleLanguageChange = (language) => {
//     setCurrentLanguage(language);
// };

useEffect(() => {
  // Local storage'dan dil değerini al
  const storedLanguage = localStorage.getItem('selectedLanguage');
  if (storedLanguage) {
    setCurrentLanguage(storedLanguage);
  }
}, []);

const handleLanguageChange = (language) => {
  // Dil değiştiğinde yapılması gereken işlemleri buraya ekleyebilirsiniz.
  
  // Dil değerini local storage'a kaydet
  localStorage.setItem('selectedLanguage', language);
  
  // Dil değerini state'i güncellemek için kullan
  setCurrentLanguage(language);
};

  return (
    <Router>
      
      <Navbar currentLanguage={currentLanguage} onLanguage={handleLanguageChange}/>
      <Routes>
        <Route path="/" element={<div>
          <Home  />
            <About currentLanguage={currentLanguage}/>
            <Product currentLanguage={currentLanguage} />
          </div>} />
          <Route path="/content_kav" element={
          <ContentKav currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_kiy" element={
          <ContentKiy currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_dil" element={
          <ContentDil currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_pur" element={
          <ContentPur currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_kab" element={
          <ContentKab currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_unu" element={
          <ContentUnu currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_nat" element={
          <ContentNat currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_bey" element={
          <ContentBey currentLanguage={currentLanguage} />
            
          } />
          <Route path="/content_about" element={
          <ContentAbout currentLanguage={currentLanguage} />
            
          } />
          <Route path="/contact" element={
          <Contact currentLanguage={currentLanguage} />
            
          } />
          </Routes>
      <Footer currentLanguage={currentLanguage}/>
      <a/>
    </Router>
  );
}

export default App;
